<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('finance.基本信息') }}</div>
      <van-collapse-item :title="$t('finance.付款信息')" name="1">
        <div class="items">
          <h5>{{ $t('finance.付款申请号') }}</h5>
          <p>{{ this.ruleForm.paymentApplicationNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.是否预付款') }}</h5>
          <p>{{ this.ruleForm.isAdvance | setDict('IS_THE_WHOLE_ORDER_SALES') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.支付方式') }}</h5>
          <p>{{ this.ruleForm.paymentMethod | setDict('PAYMENT_METHOD') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.单据日期') }}</h5>
          <p>{{ this.ruleForm.documentDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.申请支付日') }}</h5>
          <p>{{ this.ruleForm.applicationPaymentDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.业务类型') }}</h5>
          <p>{{ this.ruleForm.businessType | setDict('FEEPAYMENT_BUSINESS_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.原币币种') }}</h5>
          <p>{{ this.ruleForm.currency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.原币金额') }}</h5>
          <p>{{ this.ruleForm.originalAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.对人民币汇率') }}</h5>
          <p>{{ this.ruleForm.paymentRmbRate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.对人民币金额') }}</h5>
          <p>{{ this.ruleForm.rmbAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.状态') }}</h5>
          <p>{{ this.ruleForm.orderStatus | setDict('orderStatus') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.业务员') }}</h5>
          <p>{{ this.ruleForm.salesMan }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.业务部门') }}</h5>
          <p>{{ this.ruleForm.salesManDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.付款事由') }}</h5>
          <p>{{ this.ruleForm.paymentDesc }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.备注') }}</h5>
          <p>{{ this.ruleForm.remark }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('finance.收款方信息')" name="2">
        <div class="items">
          <h5>{{ $t('finance.收款方') }}</h5>
          <p>{{ this.ruleForm.payee }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.对方银行') }}</h5>
          <p>{{ this.ruleForm.counterpartBank }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.对方账号') }}</h5>
          <p>{{ this.ruleForm.counterpartAccount }}</p>
        </div>
        <div class="items" v-if="ruleForm.orgCode==='106'">
          <h5>IBAN.NO</h5>
          <p>{{ this.ruleForm.ibanNo }}</p>
        </div>
        <div class="items" v-if="ruleForm.orgCode==='106'">
          <h5>Swift code</h5>
          <p>{{ this.ruleForm.swiftCode }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.所在地区') }}</h5>
          <p>
            {{
              (this.ruleForm.tel? this.ruleForm.tel+'-':'')
              +(this.ruleForm.prov? this.ruleForm.prov+'-':'')
              +(this.ruleForm.city? this.ruleForm.city:'')
            }}
          </p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.详细地址') }}</h5>
          <p>{{ this.ruleForm.address }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('finance.申请明细')" name="3">
        <div v-if="!ruleForm.importFeePaymentDetailList || !ruleForm.importFeePaymentDetailList.length">{{ $t('finance.无') }}</div>
        <div v-for="(item, index) in ruleForm.importFeePaymentDetailList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('finance.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.款项类型') }}:</h5>
            <p>{{ item.payment | setDict('COST_COMPONENT_PAYMENT') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.单据类型') }}:</h5>
            <p>{{ item.businessBillType | setDict('PAYMENT_BUSIENSS_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.单据编号') }}:</h5>
            <p>{{ item.businessBillCode }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.单据金额') }}:</h5>
            <p>{{ item.importContractAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.申请金额') }}:</h5>
            <p>{{ item.paymentAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.付款已使用金额') }}:</h5>
            <p>{{ item.detailUseAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.实付金额') }}:</h5>
            <p>{{ item.financialPaidAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.币种') }}:</h5>
            <p>{{ item.currency }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.付款比例') }}:</h5>
            <p>{{ item.paymentProp == null ? 0 : item.paymentProp}}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.备注') }}:</h5>
            <p>{{ item.remarks }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('finance.商品信息')" name="commodity">
        <materialsList
          :functionalCurrency="ruleForm.currency"
          :contracts="ruleForm.importFeePaymentDetailList // 合同列表
              .filter(item => item.businessBillType === '1') // 采购合同
              .map(({ purContType, businessBillId }) => ({
                type: purContType === 'domesticPurContract' ? 'DomesticTrade' : 'Cooperative', // domesticPurContract <=> 境内
                contractId: businessBillId
              }))
          "
        />
      </van-collapse-item>

      <div class="splitTitle">{{ $t('finance.附件') }}</div>
      <van-collapse-item :title="$t('finance.附件信息')" name="4">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('finance.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('finance.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('finance.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('finance.系统信息') }}</div>
      <van-collapse-item :title="$t('finance.系统信息')" name="6">
        <div class="items">
          <h5>{{ $t('finance.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('finance.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';
import materialsList from '@/views/business/views/pur/materialsList';

export default {
  name: 'visitorFieldComponent',
  components: {
    myHistoryPanel,
    myApproval,
    materialsList,
  },
  data() {
    return {
      attachmentList: [],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'importFeePayment',
      processInstanceName: '付款申请',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      ruleForm: {
        id: '',
        paymentApplicationNo: '',
        salesMan: '',
        status: 'PREPARING',
        documentDate: '',
        applicationPaymentDate: '',
        isGroupPayments: '',
        currency: '',
        paymentMethod: '',
        financialPaidDate: '',
        financialPaidAmount: '',
        expandSharing: '',
        payee: '',
        payeeEnglishName: '',
        counterpartBank: '',
        counterpartAccount: '',
        cityInformation: '',
        remark: '',
        isAdvPayment: '',
        orderStatus: '',
        isGif: '',
        isDept: '',
        isDatePayment: '',
        createByName: '',
        createTime: '',
        lastmodifiedTime: '',
        checkReleaseStatus: '',
        checkCollection: '',
        importFeePaymentDetailList: [],
        importFeePaymentRecordList: [],
        importFeePaymentInvoiceDetailList: [],
        importFeePaymentRebateList: [],
        isGenerateInvoice: '',
        relationInvFlag: '',
        isAdvance: '',
        rmbAmount: '',
        originalAmount: '',
        paymentRmbRate: ''
      }
    };
  },
  props: {},
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    onLoad() {
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.ruleForm.importFeePaymentDetailList.push(this.ruleForm.importFeePaymentDetailList.length + 1);
        }
        this.loading = false;
        if (this.ruleForm.importFeePaymentDetailList.length >= 40) {
          this.finished = true;
        }
      }, 500);
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/finance/importFeePaymentHeader/view',
        data: {
          entity: {
            id: id
          }
        }
      }).then(res => {
        // 成功回调方法
        var data = res.data.entity;
        if (data) {
          this.ruleForm = res.data.entity;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('finance.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/finance/importFeePaymentHeader/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('finance.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/finance/importFeePaymentHeader/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/finance/importFeePaymentHeader/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('finance.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/finance/importFeePaymentHeader/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData(this.ruleForm.id);
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
